html, body {
    height: 100%;
}

body {
    font-family: Montserrat, sans-serif;
    color: #4C4948;
    background-color: #fefefe !important;
}

#root {
    position: relative;
    min-height: 100%; 
}

a {
    color: inherit;
}

span.hamburger-box {
    width: 24px;
}
span.hamburger-inner, span.hamburger-inner:after, span.hamburger-inner:before {
    width: 24px;
    height: 2px;
    background-color: #FF7D2F;
}
span.hamburger-inner {
    margin-top: -1px;
}
span.hamburger-inner:before {
    top: -6px;
}
span.hamburger-inner:after {
    bottom: -6px;
}

button.to-top-button {
    position: fixed;
    bottom: 32px;
    right: 32px;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 100%;
    z-index: 999;
    transition: all 0.5s;
    transform: translateY(150px);
}

button.to-top-button:hover {
    background-color: #FF4D4D;
}

button.to-top-button:focus {
    background-color: #E54545;
}

button.to-top-button.active {
    transform: translateY(0);
}

.newsBody .bodyText {
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.techSection .flatButton:hover .arrowIcon {
    animation: rightBounce 1s ease-out infinite;
}

.mobileMenu div:first-child {
    top: 60px !important;
}

.section.what-is-agora,
.section.connecting,
.section.roadmap {
    background: #FFF8F4;
}

.section.motivation {
    background-image: linear-gradient(#FFF, #FFF8F4);
}

.section.freedom {
    background-image: linear-gradient(#FFF8F4, #FFF);
}

.section.gamers,
.section.ownership,
.section.technology,
.section.revenue {
    background: #F6F5F5;
}

@keyframes buildIcon {
    0%{
        opacity: 0.85;
    }
    100%{
        opacity: 0.3;
    }
}

@keyframes rightBounce {
    0%{
        transform: translateX(0) translateY(3px);
    }
    50%{
        transform: translateX(4px) translateY(3px);
    }
    100%{
        transform: translateX(0) translateY(3px);
    }
}

@keyframes bottomBounce {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(4px);
    }
    100%{
        transform: translateY(0);
    }
}

@media (max-width: 1279px) {
    div.nav {
        padding: 0 5%;
    }
    div.nav a.linkText {
        margin-right: 24px;
    }
    section.section {
        padding: 72px 5%;
    }
    section.cover {
        padding: 12px 5% 0 5%;
    }
    section.aboutSection {
        padding: 72px 10%;
    }
    div.bookmarkContainer {
        padding: 0 5%;
    }

}

@media (max-width: 1024px) {
    div.nav {
        display: none;
    }
    div.mobileNav {
        display: flex;
    }
    button.localeButton {
        transform: translateY(1.5px);
    }
    header.navbar {
        height: 60px;
    }
    div.localeMenuPaper {
        top: 59px !important;
        right: 0 !important;
        width: 100%;
        max-width: 100%;
        box-shadow: rgba(255, 77, 77, 0.2) 0px 15px 30px -5px
    }
    div.wrapper {
        padding-top: 60px;
    }
    div.bookmarkOverlay {
        top: 60px;
        left: 0;
        height: calc(100vh - 60px);
        width: 28px;
        display: flex;
        align-items: center;
        z-index: 998;
        transition: all 0.3s;
        cursor: pointer;
        position: fixed;
    }
    div.bookmarkOverlay.active {
        width: 300px;
    }
    div.bookmarkTrigger {
        display: inline-flex;
    }
    div.bookmarkContainer {
        background: #FFE5D5;
        padding: 0;
        width: 300px;
        min-width: 300px;
        transform: translateX(-300px);
        transition: all 0.3s;
    }
    div.bookmarkContainer.active {
        transform: translateX(0);
    }
    div.bookmark {
        width: 300px;
        height: 500px;
        top: 0;
        padding: 30px 12px;
        box-shadow: none;
        position: absolute;
    }
    div.bookmarkPaper {
        padding: 0 28px;
        background: transparent;
    }
    div.whitepaper div.container {
        margin: 0;
    }
    div.whitepaper div.anchor {
        top: -60px;
    }
}
/*
/* tablet or small laptop */
@media (max-width: 768px) {
    section.cover {
        height: fit-content;
    }
    section.cover div.container {
        align-items: center;
    }
    div.titleContainer {
        align-items: center;
    }
    section.cover {
        padding: 0 12px 24px 12px;
        background: url('/images/jumbotron_bg.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: -80px;
        min-height: calc(100vh - 124px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }
    section.cover .button {
        display: none;
    }
    section.cover .mobileIcon {
        display: flex;
        animation: bottomBounce 1s ease-out infinite;
    }
    span.title {
        font-size: 32px;
        text-align: center;
    }
    span.slogan {
        font-size: 20px;
        margin-bottom: 32px;
        text-align: center;
    }
    button.button, button.outlinedButton, button.flatButton {
        /* width: 138.4px; */
        height: 48px;
        font-size: 20px;
        letter-spacing: 1.5px;
    }
    img.illImg {
        margin-top: -17px;
        margin-bottom: 12px;
    }
    section.techSection .titleText {
        margin-bottom: 12px;
    }
    section.techSection .body {
        padding: 24px;
    }
    section.techSection .list {
        flex-direction: column;
        margin-bottom: 32px;
    }
    section.techSection .techItem {
        width: 100%;
    }
    section.techSection .techItem:not(:last-of-type) {
        margin-bottom: 32px;
    }
    section.techSection .headLineText, section.techSection .bodyText {
        text-align: left;
    }
    div.founderList {
        margin-top: 0px;
        flex-direction: column;
    }
    div.founderItem {
        flex-direction: column;
    }
    div.memberItem {
        flex-direction: column;
    }
    img.photo {
        width:100%;
    }
    div.body {
        text-align: center;
        margin-left: 0;
    }
    section.aboutSection {
        padding-top: 72px;
    }
    section.aboutSection div.body {
        margin-bottom: 40px;
    }
    section.teamMemberSection span.headLineText {
        flex-direction: column;
    }
    section.teamMemberSection span.titleDesc {
        margin-top: 12px;
    }
    section.teamMemberSection div.founderList {
        width: 100%;
        flex-direction: column;
    }
    section.teamMemberSection div.founderItem {
        max-width: 360px;
        flex-direction: row;
        margin-left: 16px;
        margin-right: 16px;
    }
    section.teamMemberSection div.body {
        margin-left: 24px;
        text-align: left;
    }
    section.teamMemberSection div.memberList {
        margin-bottom: 40px;
        flex-direction: column;
    }
    section.teamMemberSection div.memberItem {
        max-width: 360px;
        flex-direction: row;
    }
    section.teamMemberSection div.memberItem:not(:last-child) {
        margin-bottom: 40px;
    }
    section.teamMemberSection span.memberName {
        margin-bottom: 12px;
    }
    div.link {
        margin-bottom: 32px;
    }
    div.link > a.logoLink {
        width: 100%;
        text-align: center;
        margin-right: 0;
        margin-bottom: 16px;
    }
    div.copyright {
        text-align: center;
    }
    span.titleText {
        font-size: 28px;
        margin-bottom: 40px;
    }
    span.headLineText {
        font-size: 24px;
        margin-bottom: 16px;
    }
    span.subTitleText {
        font-size: 16px;
        margin-top: -12px;
        margin-bottom: 32px;
        text-align: center;
    }
    p.paragraph {
        font-size: 16px;
        line-height: 28px;
    }
    section:not(.cover) {
        padding: 72px 12px 48px 12px;
    }
    footer.footer {
        padding: 0 5%;
    }
    button.to-top-button {
        bottom: 100px;
    }
    div.whitepaper section.section {
        padding: 30px 28px;
    }
    div.whitepaper div.block {
        flex-direction: column;
        align-items: center;
    }
    div.whitepaper div.blockHead {
        margin: 0;
    }
    div.whitepaper div.blockBody {
        align-items: center;
    }
    div.whitepaper div.connectingList {
        flex-direction: column;
        margin-bottom: 0;
    }
    div.whitepaper div.connectingItem {
        flex-direction: row;
        align-items: center;
    }
    div.whitepaper div.connectingItem img {
        margin-right: 16px;
    }
    div.whitepaper div.roadmapBlock {
        flex-direction: column;
        padding-left: 24px;
    }
    div.whitepaper div.roadmapBlock:not(:last-of-type):before {
        top: 116px;
        left: 5px;
    }
    div.whitepaper div.roadmapBlock:after {
        top: 104px;
        left: 0;
    }
    div.whitepaper div.roadmapBlockBody {
        margin: 0;
    }
    div.whitepaper section.about span.titleText {
        font-size: 24px;
    }
    div.whitepaper span.subTitleText {
        text-align: start;
    }
}